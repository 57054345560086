/* NAVBAR CSS */

.navbar {
    background-color: black;
    padding: 2%;
    color: white;
  }


  
  .logo {
    height: 45px;
  }
  
  
  .sub-navbar .nav-link.active {
    position: relative;
    color: #007bff;
  }
  
  .sub-navbar .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
  }

  .navbar-heading,
  .navbar-text {
    margin: 0;
  }
  
  .contact-button {
    padding : 1% 1% 0 1%;
    font-family: 'Times New Roman', Times, serif;
    font-size: large;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.7s;
  }
  
  
  .contact-button:hover {
    background-color: #777777;
  }
  
  .sub-navbar {
    background-color: #f8f9fa;
    padding: 5px 0;
  }
  
  .sub-navbar .nav-link {
    font-size: 14px;
    color: #000;
  }
  
  .email-text {
    margin-right: 10px;
    font-size: 14px;
  }
  
  .email-icon {
    font-size: 16px;
  }
  

  

  /* PRODUCT CSS */
  .product-item-enter {
    opacity: 0.5;
    transform: translateY(20px);
  }
  
  .product-item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .product-item-exit {
    opacity: 1;
  }
  
  .product-item-exit-active {
    opacity: 0.5;
    transform: translateY(-20px);
    transition: opacity 500ms, transform 500ms;
  }
  
  .product-item-exit-active.show-all {
    transform: translateY(20px);
  }
  


.container.product {
    padding-top: 9rem;
}

.card-img-top {
    height: 200px; /* Adjust the height as desired */
  }
  .card {
    margin-bottom: 20px; /* Adjust the margin as desired */
  }


.card:hover {
    transform: scale(1.1) !important;
  }

  

  .card-text {
    max-height: 100px; /* Adjust the max height as desired */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .card-title,
  .card-text {
    margin: 5px 0;
  }
  
  .card-price {
    font-weight: bold;
  }
  
  .load-more {
    background: none;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    margin-bottom: 6%;
  }
  
  .load-more span {
    font-size: 14px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  .load-more svg {
    vertical-align: middle;
    margin-top: -2px;
  }
  


  /* FOOTER CSS */

  .footer {
    width: 100%;
    background-color: #e2dddd;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
    justify-content: center;
    gap: 4%;
  }
  
  .footer-column {
    flex: 1; /* Make the columns equally distribute the available space */
    margin: 0;
    padding: 0 10px; /* Adjust the padding as desired */
  }
  
  .footer-heading {
    font-family: 'Times New Roman', Times, serif;
  }
  
  .footer-column p {
    margin: 5px 0;
  }
  
  /* Media Queries for Responsiveness */
  
  /* On screens larger than 768px */
  @media screen and (min-width: 768px) {
    .footer-content {
      gap: 30px; /* Increase the gap between columns */
    }
  
    .footer-column {
      flex: 0 0 calc(33.33% - 60px); /* Show 3 columns in a row with some spacing */
      padding: 0 30px; /* Increase padding on larger screens */
    }
  }
  
  /* On screens larger than 992px */
  @media screen and (min-width: 992px) {
    .footer-content {
      gap: 60px; /* Increase the gap between columns */
    }
  
    .footer-column {
      flex: 0 0 calc(25% - 80px); /* Show 4 columns in a row with some spacing */
      padding: 0 40px; /* Increase padding on larger screens */
    }
  }
  