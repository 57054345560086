/* NAVBAR CSS */

.navbar {
    background-color: black;
    padding: 2%;
    color: white;
  }
  
  .logo {
    height: 45px;
  }
  
  
  .sub-navbar .nav-link.active {
    position: relative;
    color: #007bff;
  }
  
  .sub-navbar .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
  }
  
  
  .navbar-heading,
  .navbar-text {
    margin: 0;
  }
  
  .contact-button {
    padding : 1% 1% 0 1%;
    font-family: 'Times New Roman', Times, serif;
    font-size: large;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.7s;
  }
  
  .contact-button:hover {
    background-color: #777777;
  }
  
  .sub-navbar {
    background-color: #f8f9fa;
    padding: 5px 0;
  }
  
  .sub-navbar .nav-link {
    font-size: 14px;
    color: #000;
  }
  
  .email-text {
    margin-right: 10px;
    font-size: 14px;
  }
  
  .email-icon {
    font-size: 16px;
  }
  

  /* ABOUT CSS */

.about {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 6%;
    margin-top: 7rem;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .about h1 {
    font-weight: bolder;
    margin-bottom: 4%;
  }
  
  .about span {
    font-size: 18px;
    margin: -1.5% 0 3% 0; /* Remove all margins */
    word-spacing: 2px;
    line-height: 1.6; /* Adjust line-height as needed */
  }
  
  .about h5 {
    margin: 0;
    font-weight: bolder;
  }
  


  
  /* FOOTER CSS */


  .footer {
    width: 100%;
    background-color: #e2dddd;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
    justify-content: center;
    gap: 4%;
  }
  
  .footer-column {
    flex: 1; /* Make the columns equally distribute the available space */
    margin: 0;
    padding: 0 10px; /* Adjust the padding as desired */
  }
  
  .footer-heading {
    font-family: 'Times New Roman', Times, serif;
  }
  
  .footer-column p {
    margin: 5px 0;
  }
  
  /* Media Queries for Responsiveness */
  
  /* On screens larger than 768px */
  @media screen and (min-width: 768px) {
    .footer-content {
      gap: 30px; /* Increase the gap between columns */
    }
  
    .footer-column {
      flex: 0 0 calc(33.33% - 60px); /* Show 3 columns in a row with some spacing */
      padding: 0 30px; /* Increase padding on larger screens */
    }
  }
  
  /* On screens larger than 992px */
  @media screen and (min-width: 992px) {
    .footer-content {
      gap: 60px; /* Increase the gap between columns */
    }
  
    .footer-column {
      flex: 0 0 calc(25% - 80px); /* Show 4 columns in a row with some spacing */
      padding: 0 40px; /* Increase padding on larger screens */
    }
  }
  