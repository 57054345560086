.navbar {
  background-color: black;
  padding: 2%;
  color: white;
}

.logo {
  height: 45px;
}


.sub-navbar .nav-link.active {
  position: relative;
  color: #007bff;
}

.sub-navbar .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #007bff;
}


.navbar-heading,
.navbar-text {
  margin: 0;
}

.contact-button {
  padding : 1% 1% 0 1%;
  font-family: 'Times New Roman', Times, serif;
  font-size: large;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: background-color 0.7s;
}

.contact-button:hover {
  background-color: #777777;
}

.sub-navbar {
  background-color: #f8f9fa;
  padding: 5px 0;
}

.sub-navbar .nav-link {
  font-size: 14px;
  color: #000;
}

.email-text {
  margin-right: 10px;
  font-size: 14px;
}

.email-icon {
  font-size: 16px;
}



  /* Heading CSS */
  
  .heading {
    color: rgb(0, 0, 0);
    padding-top: 8rem;
    font-family:Georgia, 'Times New Roman', Times, serif;
  }


  .parcel img {
    margin-top: 1%;
    width: 100%;
    height: 500px;
    object-fit:cover;
    margin-bottom: 10%;
  }



  
/* Carousel CSS */

.carousel {
  margin-bottom: 10%;
  transition: transform 0.9s !important; 
}

.carousel-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0 10px;
  transition: transform 0.3s;
}

.carousel-image.active {
  width: 200px;
  height: 200px;
  z-index: 1;
  transition: transform 0.3s;
}

.carousel-image.active:hover {
  transform: scale(1.1) !important
}

.prev-button,
.next-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}


/* Responsive styles */

@media (max-width: 768px) {
  .carousel-image {
    width: 80px;
    height: 80px;
    margin: 0 5px;
  }
  
  .carousel-image.active {
    width: 120px;
    height: 120px;
  }
  
  .prev-button,
  .next-button {
    font-size: 18px;
    padding: 5px;
  }
}



/* Responsive styles */

@media (max-width: 768px) {
  .carousel-image {
    width: 80px;
    height: 80px;
    margin: 0 5px;
  }
  
  .carousel-image.active {
    width: 120px;
    height: 120px;
  }
  
  .prev-button,
  .next-button {
    font-size: 18px;
    padding: 5px;
    margin: 5px auto; /* Center the buttons and create space between them */
    display: block; /* Display the buttons as block elements */
  }
}



/* ABOUT CSS */

.about {
  width: 70%;
  margin-left: 15%;
  display: flex;
  gap: 20px;
  margin-bottom: 6%;
}

.left-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 40px 0;
  margin-right: 30px;
}

.left-part h3 em {
  font-weight: normal;
  font-family: 'Courier New', Courier, monospace;
}

.left-part p {
  font-family: 'Times New Roman', Times, serif;
}

.right-part {
  flex: 1;
  background-color: black;
  position: relative; /* Add position relative */
}

.right-part img {
  width: 98%;
  height: 98%;
  object-fit: cover;
  position: absolute; /* Add position absolute */
  bottom: 6%; /* Adjust the bottom position as desired */
  right: 6%; /* Adjust the right position as desired */
}

/* Responsive Styles */

@media (max-width: 768px) {
  .about {
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    gap: 10px;
  }

  .left-part {
    margin-right: 0;
    padding: 20px 0;
  }

  .right-part img {
    position: static;
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
}




/* PRODUCT CSS */

/* Add this CSS in your stylesheets */
.product-item-enter {
  opacity: 0.5;
  transform: translateY(20px);
}

.product-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.product-item-exit {
  opacity: 1;
}

.product-item-exit-active {
  opacity: 0.5;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

.product-item-exit-active.show-all {
  transform: translateY(20px);
}


.card-img-top {
  height: 20%; /* Adjust the height as desired */
  object-fit: cover;

}
.card {
  margin-bottom: 20px; /* Adjust the margin as desired */
  cursor: pointer;
}

.card:hover{
  transition: transform 0.3s;
}

.card-text {
  max-height: 100px; /* Adjust the max height as desired */
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-card-title,
.card-text {
  margin: 5px 0;
}

.card-price {
  font-weight: bold;
}

.load-more {
  background: none;
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  margin-bottom: 6%;
}

.load-more span {
  font-size: 14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.load-more svg {
  vertical-align: middle;
  margin-top: -2px;
}

.best-seller-tag {
  position: absolute;
  padding: 3% 4%;
  background-color: rgb(174, 189, 204);
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
  z-index: 1; /* Make sure the tag appears above the image */
  font-family: 'Times New Roman', Times, serif;
}






/* CONTACT CSS */
.contact {
  width: 80%;
  margin-left: 10%;
  height: auto;
  background-color: #e2dddd;
  display: flex;
  gap: 20px;
  margin-bottom: 6%;
}

.left-section {
  padding: 90px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-heading {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: normal;
  font-size: 38px;
  margin-top: 10px;
  text-align: center;
}

.contact-form {
  margin-top: 20px;
  width: 80%; /* Adjust the width as needed */
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 16px;
}

input,
textarea {
  width: 95%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  outline: none;
  background-color: #e2dddd;
}

textarea#message {
  height: 120px; /* Adjust the height as needed */
}

input::placeholder,
textarea::placeholder {
  color: #666;
}

.submit-button {
  margin-top: 3%;
  width: 100%; /* Button width matches input fields' width */
  padding: 10px;
  font-family: 'Times New Roman', Times, serif;
  font-size: large;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover{
  background-color: #777777;
  transition: 0.9s;
}

.right-section {
  flex: 1;
}

.contact-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.fade-in-out {
  animation: fade-in-out 1s ease-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


/* FOOTER CSS */

.footer {
  width: 100%;
  background-color: #e2dddd;
  text-align: center;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  justify-content: center;
  gap: 20px;
}

.footer-column {
  flex: 1; /* Make the columns equally distribute the available space */
  max-width: 250px; /* Limit the maximum width of each column */
  margin: 0 auto; /* Center the columns */
  padding: 0 10px; /* Adjust the padding as desired */
}

.footer-heading {
  font-family: 'Times New Roman', Times, serif;
}

.footer-column p {
  margin: 5px 0;
}

/* Media Queries for Responsiveness */

/* On screens larger than 768px */
@media screen and (min-width: 768px) {
  .footer-content {
    gap: 30px; /* Increase the gap between columns */
  }

  .footer-column {
    flex: 0 0 calc(33.33% - 60px); /* Show 3 columns in a row with some spacing */
    padding: 0 30px; /* Increase padding on larger screens */
  }
}

/* On screens larger than 992px */
@media screen and (min-width: 992px) {
  .footer-content {
    gap: 60px; /* Increase the gap between columns */
  }

  .footer-column {
    flex: 0 0 calc(25% - 80px); /* Show 4 columns in a row with some spacing */
    padding: 0 40px; /* Increase padding on larger screens */
  }
}
