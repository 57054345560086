.contact-btn {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    background-color: #fff;
    border: 2px solid #000;
    color: #000;
    padding: 12px 24px;
    border-radius: 5px;
  }


.cursive-heading {
    font-family: 'Times New Roman', cursive;
    font-size: 46px; /* Adjust the font size as needed */
    margin-bottom: 5%; /* Adjust the margin as needed */
  }
  


/* YourComponent.css */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.container.faq {
  margin: 0 auto; /* Center the container horizontally */
  max-width: 80%; /* Adjust the max-width as needed */
}

/* Vertically align answers within a row */
.container.faq .row::after {
  content: "";
  flex: auto;
}

.container.faq .row .col-md-6 {
  display: flex;
  flex-direction: column;
}

.container.faq .row .col-md-6 h3 {
  margin-bottom: 0.5rem; /* Add some spacing below the question */
}

.container.faq .row .col-md-6 p {
  flex-grow: 1;
  margin-top: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%; /* Display one question and answer per row */
    max-width: 100%;
  }
}
