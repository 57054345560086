.navbar {
    background-color: black;
    padding: 2%;
    color: white;
  }
  
  .logo {
    height: 45px;
  }
  
  
  .sub-navbar .nav-link.active {
    position: relative;
    color: #007bff;
  }
  
  .sub-navbar .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
  }
  
  
  .navbar-heading,
  .navbar-text {
    margin: 0;
  }
  
  .contact-button {
    padding : 1% 1% 0 1%;
    font-family: 'Times New Roman', Times, serif;
    font-size: large;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.7s;
  }
  
  .contact-button:hover {
    background-color: #777777;
  }
  
  .sub-navbar {
    background-color: #f8f9fa;
    padding: 5px 0;
  }
  
  .sub-navbar .nav-link {
    font-size: 14px;
    color: #000;
  }
  
  .email-text {
    margin-right: 10px;
    font-size: 14px;
  }
  
  .email-icon {
    font-size: 16px;
  }
  
  /* HEADING CSS */
  /* .heading {
    background-color: #f8f9fa; /* Add your desired background color 
  } */
  
  .heading h3 {
    font-size: 24px; /* Add your desired font size */
    /* margin-bottom: 0; Remove any extra margin at the bottom */
  }



  .login-btn {
    
    margin-left: -65px;
    background-color: inherit;
    border: none;
  }

  .login-btn:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
  }



  /* Styles for mobile devices */
@media (max-width: 576px) {
    .heading {
      text-align: center;
      padding: 10px;
    }
    
    .display-4 {
      font-size: 24px;
      margin-left: 0;
    }
  
    .search-btn {
        margin-left: 50px;
        margin-bottom: 20px;
    }

    

    .login-btn {
        display: block;
        width: 100%;
        margin-left: 5px;
        margin-bottom: 10px;
      }

  }
  
  /* Styles for tablets */
  @media (min-width: 576px) and (max-width: 992px) {
    .display-4 {
      font-size: 32px;
    }
    
    .search-btn {
        margin-left: 50%;
        margin-bottom: 15px;
    }
  

    .login-btn {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
      }
  }
  
  /* Styles for desktop screens */
  @media (min-width: 992px) {
    .heading {
      text-align: left;
    }
  
    .display-4 {
      font-size: 48px;
      margin-left: 250px;
    }
  
    

  
  }

  
  /* BLOGS CSS */

  .custom-card {
    width: 800px;
    height: auto;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding-bottom: 2%;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .card-image {
    width: 100%;
    height: 350px;
  }
  
  .blog-card-body {
    padding: 20px 20px 20px 0;
    margin-right: 5%;
  }
  
  .avatar {
    float: left;
  }
  
  .avatar-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 80px;
  }
  
  .user-info {
    display: flex; /* Use flexbox */
    align-items: center; /* Align items vertically center */
    /* margin-left: 90px; */
  }
  
  .user-name {
    font-size: 15px;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  
  .user-date {
    font-size: 15px;
    color: #999;
    margin-left: 5px;
  }
  
  .menu {
    /* No need for display: flex or align-items */
    /* Just add margin-left: auto to push the icon to the right */
    margin-left: auto;
  }
  
  .menu-icon {
    margin-left: 1%;
    margin-bottom: 70%;
  }
  

  .text-area {
    float: left;
  }
  
  .card-title {

     margin-top: 10px; 
     margin-left: 80px; 
    font-size: 24px;
  }
  
  .card-description {
    margin-left: 80px;
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
  
  .divider {
    margin-top: 20px;
    width: 90%;
    margin-left: 80px;

    border: 1px solid #ccc;
  }
  
  .views {
    float: left;
    display:flex;
    justify-content: space-between;
  }



  .like-icon {
    margin-left: 100%;
    margin-bottom: 60%;
  }

  
  /* Media Queries */
@media (max-width: 992px) {
    .custom-card {
      width: 90%;
      height: auto;
    }
    
    .card-image {
      height: 250px;
    }
  
    .avatar-image {
      margin-left: 20px;
    }
    
    .card-title,
    .card-description {
      margin-left: 20px;
    }
    
    .divider {
      margin-left: 20px;
    }
    
    .views {
      margin-left: 20px;
    }
  }

  /* FOOTER CSS */
  
  .footer {
    width: 100%;
    background-color: #e2dddd;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: flex-start;
    gap: 4%;
  }
  
  .footer-column {
    margin: 0;
    padding: 0 10px; /* Adjust the padding as desired */
  }
  
  .footer-heading {
    font-family: 'Times New Roman', Times, serif;
  }
  
  .footer-column p {
    margin: 5px 0;
  }
  