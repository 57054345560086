/* PRODUCT CSS */

.left-container,
.right-container {
  padding: 5%;
}

.divider-vertical {
  height: 100%;
  border-left: 1px solid;
  margin: 0 10px;
}

.cart-button:hover {
  background-color: #777777 !important;
  border-color: #777777 !important;
}





.product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.product-description-heading {
  font-family: 'Your Fancy Font', cursive;
  font-size: 24px;
  height: auto; /* Adjust the height as desired */
}

.description-toggle {
  background: none;
  border: none;
  font-size: 24px;
  color: inherit;
  cursor: pointer;
}

.description-toggle.open {
  transform: rotate(180deg);
}

.description-content-enter {
  opacity: 1;
  transform: translateY(100%);
}

.description-content-enter-active {
  opacity: 0.5;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.description-content-exit {
  opacity: 0.5;
  transform: translateY(0);
}

.description-content-exit-active {
  opacity: 0.1;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}

.description-divider {
  border: 1px solid #777777;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: transform ease-in-out 0.3s;
}




/* FOOTER CSS */
.footer {
  width: 100%;
  background-color: #e2dddd;
  text-align: center;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  justify-content: center;
  gap: 4%;
}

.footer-column {
  flex: 1; /* Make the columns equally distribute the available space */
  margin: 0;
  padding: 0 10px; /* Adjust the padding as desired */
}

.footer-heading {
  font-family: 'Times New Roman', Times, serif;
}

.footer-column p {
  margin: 5px 0;
}

/* Media Queries for Responsiveness */

/* On screens larger than 768px */
@media screen and (min-width: 768px) {
  .footer-content {
    gap: 30px; /* Increase the gap between columns */
  }

  .footer-column {
    flex: 0 0 calc(33.33% - 60px); /* Show 3 columns in a row with some spacing */
    padding: 0 30px; /* Increase padding on larger screens */
  }
}

/* On screens larger than 992px */
@media screen and (min-width: 992px) {
  .footer-content {
    gap: 60px; /* Increase the gap between columns */
  }

  .footer-column {
    flex: 0 0 calc(25% - 80px); /* Show 4 columns in a row with some spacing */
    padding: 0 40px; /* Increase padding on larger screens */
  }
}
